import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';

import './slider.css';

export default function ShowCase(props) {

    const FIRST_IMAGE = {
        imageUrl: props.first_image,
    }

    const SECOND_IMAGE = {
        imageUrl: props.second_image,
    }

    return <ReactBeforeSliderComponent
        className='slider'
        firstImage={FIRST_IMAGE}
        secondImage={SECOND_IMAGE}
    />
}