import Gallery from './Gallery';

import LocationOnIcon from '@mui/icons-material/LocationOn';

export default function Header() {
    return <div id="header">
        <Gallery />
        <div id="text">
            <p>
                Add value to your home with our roof painting services.
            </p>

            <div id="location">
                <LocationOnIcon />
                <p>Dunedin & Otago</p>
            </div>
        </div>
    </div>
}