
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function Footer() {
    return (
        <Box sx={{ bgcolor: '#151F40', p: 4 }} component="footer">
            <Grid container spacing={6} justify="space-evenly">
                <Grid item xs={12} sm={4} md={4}>
                    <Typography variant="h6" align="center" color="white" gutterBottom>
                        Contact
                    </Typography>
                    <Typography variant="subtitle1" align="center" color="white" component="p">
                        Email: <a href="mailto:cgroofpainting@gmail.com" class="whiteLink">Email Here</a>
                    </Typography>
                    <Typography variant="subtitle1" align="center" color="white" component="p">
                        Phone: <a href="tel:0226954822" class="whiteLink">022 695 4822</a>
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={4} md={4} sx={{ textAlign: "center" }}>
                    <Typography variant="h6" align="center" color="white" gutterBottom>
                        Experience
                    </Typography>
                    <Typography color="white">
                        3 Years Roof Restoration experience.<br />
                        2 Years Automotive Spray Painting.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={4} md={4} sx={{ textAlign: "center" }}>
                    <Typography variant="h6" align="center" color="white" gutterBottom>
                        Other Services:
                    </Typography>
                    <Typography color="white">
                        Roof moss treatments.<br />Roof Re-pointing.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}