import './App.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Navbar from './components/navbar';
import Header from './components/Header';
import WorkShowcaseList from './components/ImageTiles';
import Footer from './components/Footer';
import ShowCase from './components/ShowCase';
import MessageUs from './components/MessageUs';


const SMALL = 12
const LARGE = 12

// First set of images in panel
const images1 = [
  {
    title: "Chance painting a roof in Dunedin",
    path: 
    "https://i.imgur.com/gmdNLpw.jpeg",
  },
  {
    title: "Invercargil",
    path: "https://i.imgur.com/VyVUHXA.jpg",
  },
  {
    title: "an A frame house in Queenstown NZ",
    path: "https://i.imgur.com/RxrIy1E.jpg",
  },
  {
    title: "an A frame house in Queenstown NZ",
    path: "https://i.imgur.com/V1nF40f.jpg",
  }
]

const images2 = [
  {
    title: "House in Pine Hill, Dunedin",
    path: "https://i.imgur.com/SyvjUTt.jpg",
  },
  {
    title: "House in Pine Hill, Dunedin",
    path: "https://i.imgur.com/RbQKH6g.jpg",
  },
  {
    title: "South Otago House Customs",
    path: "https://i.imgur.com/OC8xqqz.jpg",
  },
  {
    title: "Cafe/Bar in Glenorchy",
    path: "https://i.imgur.com/wXe46Fo.jpg",
  }
]

const images3 = [
  {
    title: "House with scaffolding",
    path: "https://i.imgur.com/cYLraaQ.jpg",
  },
  {
    title: "Invercargil",
    path: "https://i.imgur.com/69AdmGj.jpeg",
  },
  {
    title: "Invercargil",
    path: "https://i.imgur.com/CawV5OV.jpeg",
  },
  {
    title: "Invercargil",
    path: "https://i.imgur.com/E9LN3pa.jpeg",
  }
]


const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial"',
  },
  a: {
    decoration: 'none',
  }
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navbar />

      <MessageUs />
      
      <Header />

      <Container sx={{ bgcolor: 'white', borderRadius: 2, p: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={SMALL} >
            {/* rgba(21,31,64,0.95) */}
            <Box sx={{ bgcolor: 'white', borderRadius: 2, p: 1, pt: 4 }} component="footer">
              <ShowCase first_image='https://i.imgur.com/OVZdwIf.png' second_image='https://i.imgur.com/zXRLgUE.png' />
            </Box>
          </Grid>

          <Grid item xs={SMALL} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Box component="footer">
              <Typography variant="h5" align="center" color="black" paragraph sx={{ p: 4 }}>
                The benefits of painting your roof can prolong the shelf life of your roof by strengthening and
                protecting it from the harsh weather and the suns UV rays. Quality roof paint is affordable and
                lasts for long periods of time if applied correctly.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={SMALL}>

            <Box sx={{ bgcolor: 'background.paper', p: 1, alignContent: 'center' }} component="footer">
              <WorkShowcaseList images={images1} />
            </Box>
          </Grid>

          <Grid item xs={SMALL} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography sx={{ pt: 3, pb: 8 }} variant="h5" align="center" color="black" paragraph>
              The roof coating paint I use, Wattyl Solagard Roof, is tough 100% acrylic satin finish with such exceptional resistance to sun and weather that it comes with a 15 year warranty. Solagard roof is self priming and formulated to give exceptional adhesion and contains mica for extra protection against the elements.<br />
              2 coats = 10 Year warranty against flaking, blistering and peeling.<br />
              3 coats = 15 Year warranty against flaking, blistering and peeling.
            </Typography>
          </Grid>

          <Grid item xs={SMALL}>
            <Box sx={{ bgcolor: 'white', borderRadius: 2, p: 1 }} component="footer">
              <ShowCase first_image='https://i.imgur.com/sBDhHU5.png' second_image='https://i.imgur.com/r7r7Rra.png' />
            </Box>
          </Grid>

          <Grid item xs={SMALL} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="h4" align="center" sx={{ pt: 4 }}>
              Process:
            </Typography>
            <Typography sx={{ pt: 0, pb: 8 }} variant="h5" align="center" color="black" paragraph>
              Waterblast<br />
              Re-screw if needed<br />
              Rust treat if needed<br />
              Seal joins with paintable silicone<br />
              Apply roofing patches if needed<br />
              2-3 coats of Solagard Roof paint
            </Typography>
          </Grid>

          <Grid item xs={SMALL}>
            <Box sx={{ bgcolor: 'background.paper', p: 1, alignContent: 'center' }} component="footer">
              <WorkShowcaseList images={images2} />
            </Box>
          </Grid>

          <Grid item xs={SMALL}>
            <Box sx={{ bgcolor: 'white', borderRadius: 2, p: 4, textAlign: 'center' }} component="footer">
              <Typography variant="h4" >CALL NOW FOR A FREE QUOTE!</Typography>
              <Typography variant="h3" ><a href="tel:0226954822" class="blackLink">022 695 4822</a></Typography>
            </Box>
          </Grid>

          <Grid item xs={SMALL}>
            <Box sx={{ bgcolor: 'white', borderRadius: 2, p: 1, }} component="footer">
              <ShowCase first_image='https://i.imgur.com/Fo5R3mZ.jpeg' second_image='https://i.imgur.com/3WWVCYY.jpeg' />
            </Box>
          </Grid>

          <Grid item xs={SMALL}>
            <Box sx={{ bgcolor: 'background.paper', p: 1, alignContent: 'center' }} component="footer">
              <WorkShowcaseList images={images3} />
            </Box>
          </Grid>

          <Grid item xs={SMALL} sx={{ textAlign: "center" }}>
            <Typography variant="h4" align="center" sx={{ p: 2, pb: 6 }}>
              Working in and around the Dunedin/Otago area, I can help you with any roof painting needs.
            </Typography>
            <iframe xs={{ width: "100%" }} width="100%" maxWidth={'100%'} height="300" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Dunedin+(My%20Business%20Name)&amp;t=&amp;z=9&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/truck-gps/">transport gps</a></iframe>
          </Grid>
        </Grid>

      </Container>


      <Footer />

    </ThemeProvider>
  );
}




export default App;
