import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { doc, setDoc, addDoc, collection, getFirestore } from "firebase/firestore";
import { app } from './firebaseConfig';
import { Input, TextField } from '@mui/material';
import { Label } from '@mui/icons-material';

const db = getFirestore(app);
const col = collection(db, 'quotes');

export default function GetQuote(props) {
    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [error, setError] = React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        if (name === '' || email === '' || phone === '' || message === '') {
            setError('Please fill out all fields');
        } else {
            addDoc(collection(db, "quotes"), {
                to: ['cgroofpainting@gmail.com'],
                message: {
                    subject: 'Request for quote on Website',
                    text: 'Name: ' + name + '\nEmail: ' + email + '\nPhone: ' + phone + '\nMessage: ' + message,
                    html: ''
                }
            })

            setName('');
            setEmail('');
            setPhone('');
            setMessage('');

            setError('Message has been sent, thank you');
        }
    }

    return (
        <div>
            <button id="contact" onClick={handleClickOpen}>
                Get a Quote
            </button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Leave your details and we will get back to you
                </DialogTitle>
                <DialogContent>
                    {error && <Typography variant="body1" color={error == "Message has been sent, thank you" ? 'green' : 'red'}>{error}</Typography>}
                    <FormControl sx={{ pt: 1 }} style={{ width: '100%' }}>
                        <TextField sx={{ p: 1 }} label="Name" value={name} onChange={(e) => setName(e.target.value)} />

                        <TextField sx={{ p: 1 }} label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />

                        <TextField sx={{ p: 1 }} label="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />

                        <TextField sx={{ p: 1 }} label="Message"
                            type="string"
                            fullWidth
                            multiline={true}
                            rows={3}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)} />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Close
                    </Button>
                    <Button onClick={handleSubmit} autoFocus>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}