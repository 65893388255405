
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import PhoneIcon from '@mui/icons-material/Phone';

const theme = createTheme();

export default function Navbar() {
    return <div id="navBar">
        <a href="/" id="logo">
            <img src="CGLogo.png" alt="CG Roof Painting" />
        </a>

        <div id="number">
            <PhoneIcon />
            <a href="tel:0226954822" class="whiteLink"><p>022 695 4822</p></a>
        </div>
    </div>
}