import * as React from 'react';

import { Grid } from '@mui/material';


export default function WorkShowcaseList(props) {
    return (<div>
        <Grid container spacing={2}>
            {props.images.map((image, i) => (
                <Grid sx={{ maxHeight: "500px"}}item xs={12} sm={12} md={6} lg={6} key={i}>
                    <img
                        class="imageTile"
                        src={image.path}
                        alt={image.title}
                        loading="lazy"
                    />
                </Grid>
            ))}
        </Grid>
    </div>
    );
}
