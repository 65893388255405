import React from 'react';

export default class Gallery extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            imageIndex: 0,
            images: [
                "https://i.imgur.com/V4d4IVm.jpeg",
                `${process.env.PUBLIC_URL + "images/galleryGif.gif"}`,
                "https://i.imgur.com/nmVPTsk.jpg",
                "https://i.imgur.com/aq4jzqy.jpg",
                
            ]
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState({ imageIndex: (this.state.imageIndex + 1) % this.state.images.length })
        }, 4500);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return <>
            <img class="gallery" src={this.state.images[this.state.imageIndex]} />
        </>
    }
}